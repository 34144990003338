<div class="card-body">

  <app-date-filter (itemConcat)="filterByDate($event)" (itemListAll)="loadListejoueurs()"></app-date-filter>
  <div class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th><div>Active</div>/desactive</th>
        <th></th>
        <th>Nom</th>
        <th>Prénoms</th>
        <th>email</th>
        <th>Téléphone</th>
        <th>Adresse</th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listUtilisateurs" class="pointer-cl">
        <td>
          <input style="margin-left: 35%" type="checkbox" (change)="activedesactive(item?.id, $event)" [checked]="item.status ==='1'" >
        </td>
        <td (click)="showsImage(item.avatar)">
          <div *ngIf="item.avatar != '' && item.avatar != null">
            <img alt="photos" src="{{urlserver + 'avatar/' + item.avatar}}" style="width: 50px; height: 43px; padding:5px; borderRadius:3px; margin: 4px;"/>
          </div>
          <div *ngIf="item.avatar == '' || item.avatar == null">
            <img alt="photos" src="../../../assets/images/avatar.png" style="width: 50px; height: 43px; padding:5px; borderRadius:3px; margin: 4px;"/>
          </div>
        </td>
        <td >{{item.nom !== null ? (item.nom | titlecase) : ''}}</td>
        <td >{{item.prenom !== null ? item.prenom : ''}}</td>
        <td >{{item?.email  !== null ? (item?.email  | lowercase) : ''}}</td>
        <td >{{item.telephone ? item.telephone : ''}}</td>
        <td >{{item.adresse != null ? item.adresse : ''}}</td>
        <td style="display: flex; flex-direction: row">
          <button style="margin-right: 5px;" class="btn btn-sm btn-secondary"  (click)="emitNavigateToShowArticles(item)">
            <i class="fa fa-newspaper"></i>
            <span *ngIf="item.articles.length>0" style="margin-left:4px; border-radius: 15px;
                     padding-top:2px;padding-bottom: 2px;padding-left: 4px; padding-right: 4px;
                      background-color: #478edb; color:#fff; font-size: 10px;">{{item.articles.length}}</span>
          </button>
          <button style="margin-right: 5px;" class="btn btn-sm btn-secondary"  (click)="emitNavigateToShowReservation(item)">
            <i class="fa fa-cart-arrow-down"></i>
            <span *ngIf="item.paiements.length>0" style="margin-left:4px; border-radius: 15px;
                     padding-top:2px;padding-bottom: 2px;padding-left: 4px; padding-right: 4px;
                      background-color: #478edb; color:#fff; font-size: 10px;">{{item.paiements.length}}</span>
          </button>
          <!--          <button style="margin-right: 5px;" class="btn btn-sm btn-success"  (click)="emitnavigateToNavigation(item.id)">-->
          <!--            <i class="fas fa-edit"></i>-->
          <!--          </button>-->
        </td>
        <td><button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
          <i class="fas fa-trash"></i>
        </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

<ng-template #showImage>
  <div style="width: 480px; height: 400px; display: flex; align-items: center; justify-content: center;">
    <img style="width: 400px; height: 400px;" src="{{oneurlimage}}"  />
  </div>
</ng-template>
