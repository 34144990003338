<div class="card-body">

  <app-date-filter1 (itemConcat1)="filterByDate($event)" (itemListAll1)="load()"></app-date-filter1>

  <div class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigers">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th>Biens</th>
        <th>Catégories</th>
        <th>Adresse</th>
        <th>Options</th>
        <th>Convive</th>
        <th>Last minute</th>
        <th>Crée le</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of dataArticle" class="pointer-cl">
        <td >
          <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieurs(item?.id, $event)" >
        </td>
        <td (click)="showsImage(item?.photoCoverture)">
          <div *ngIf="item?.photoCoverture != '' && item?.photoCoverture != null">
            <img alt="photos" src="{{urlserver + 'article/' + item?.photoCoverture}}" style="width: 60px; height: 45px; padding:5px; borderRadius:3px; margin: 4px;"/>
          </div>
          <div *ngIf="item?.photoCoverture == '' || item?.photoCoverture == null">
            <img alt="photos" src="../../../assets/images/placeholderImage.png" style="width: 60px; height: 45px; padding:5px; borderRadius:3px; margin: 4px;"/>
          </div>
        </td>
        <td>{{item?.nomArticle }}</td>
        <td>{{item?.categorie?.nomCategorie }}</td>
        <td>{{item?.adresse }}</td>
        <td>
          <div *ngIf="item?.infooptions.length>0" >
            <span *ngFor="let item1 of item?.infooptions" >
              <span>{{" "+item1?.option?.libelle + " " + (item1.gratuit == true ? ":gratuit" : item1.prix + "€" )  }}</span>
            </span>
          </div>
        </td>
        <td>{{item.nbConvive}}</td>
        <td>{{item.lastminute == "1" ? "Oui (" + formatLastMinute(item.startlastminute, item.endlastminute) + ")"  : "Non"}}</td>
        <td>{{item.createdAt | date:'yyyy-MM-dd'}}</td>
        <td style="display: flex; flex-direction: row">
          <button style="margin-right: 5px;" class="btn btn-sm btn-secondary" (click)="emitNavigateToShowReservation(item)" >
            <i class="fa fa-cart-arrow-down"></i>
            <span *ngIf="item.paiements.length>0" style="margin-left:4px; border-radius: 15px;
                     padding-top:2px;padding-bottom: 2px;padding-left: 4px; padding-right: 4px;
                      background-color: #478edb; color:#fff; font-size: 10px;">{{item.paiements.length}}</span>
          </button>
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <button class="btn btn-sm btn-danger" (click)="openModaPourSupprimerPlusieurs()">
    Supprimer les items cochés
  </button>
</div>

<ng-template #confirmCheckDeletePls>
  <div class="card-body">
    Êtes-vous sûr de vouloir supprimer ces items?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="supprimerPlusieurs()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

<ng-template #showImage>
  <div style="width: 480px; height: 400px; display: flex; align-items: center; justify-content: center;">
    <img style="width: 400px; height: 400px;" src="{{oneurlimage}}"  />
  </div>
</ng-template>
