import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import { JoueurService } from 'src/app/services/joueur.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {AdminService} from "../../services/admin.service";
import {ENV_PROD} from "../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-listerutilisateur',
  templateUrl: './Listerutilisateur.component.html',
  styleUrls: ['./Listerutilisateur.component.css']
})
export class listerutilisateurComponent implements OnInit {


  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @Output() navigateToAfficherJoueur: EventEmitter<any> = new EventEmitter();
  @Output() navigateToNavigation: EventEmitter<any> = new EventEmitter();
  @ViewChild('showImage') showImage: any;


  public urlserver: string = ENV_PROD.urlApi;
  public utilisateurId: any = null;
  public oneurlimage: string = "";
  public listUtilisateurs: any = [];
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public emitData: EventEmitter<any> = new EventEmitter;

  constructor(
    private alertService: AlertService,
    private httpClient: HttpClient,
    private listerutilisateurService: JoueurService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>{ this.spinner.hide()},10000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 3, 'desc' ]],
      responsive: true
    };
    
    this.getallutilisateurs()


  }

  ngOnChanges(): void {
    this.spinner.show();
    setTimeout(()=>{ this.spinner.hide()},10000);
    this.getallutilisateurs()
  }


  public showsImage(urlimage) {
    if(urlimage != null && urlimage != ""){
      this.oneurlimage = this.urlserver + 'avatar/' + urlimage;
      this.ngbModal.open(this.showImage, {windowClass: 'lg'})
    }
  }


  private getallutilisateurs(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'users/getalladmin',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listUtilisateurs = res.data;
            this.dtTigger.next();
          }
        });
  }

  /**
   * loadListejoueurs
   */
  public loadListejoueurs() {
    this.spinner.show();
    setTimeout(()=>{ this.spinner.hide()},10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.getallutilisateurs();
    })
  }


  public async activedesactive(id, event) {
    this.spinner.show();
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    setTimeout(()=>this.spinner.hide(),6000);
    this.httpClient.get<any>(ENV_PROD.urlApi + 'users/activedesactive/' + id + '/' + event.target.checked,
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listUtilisateurs = res.data;
          }
      });
  }


    /**
   * filterByDate
   */
  public filterByDate(item) {
    let dateSplte = item.split(',');
    this.getBy2date(dateSplte[0], dateSplte[1])
  }

  public async getBy2date(start, end) {
    this.spinner.show();
    setTimeout(()=>{  this.spinner.hide() },10000);
    let token =  await localStorage.getItem('userToken');
    let adminId = localStorage.getItem('adminId');
    this.adminService.gettokenbyid(adminId)
      .subscribe(async (res: any) => {
        localStorage.setItem('userToken', res.data);
        token = res.data;
      });
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      let body = {
        startDate: start,
        endDate: end
      };
      this.listerutilisateurService.getJoueursBetweenTwoDates(token, body)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.listUtilisateurs = res.data;
          this.dtTigger.next()
        })
    })
  }


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.utilisateurId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }



  /**
   * delete
   */
  public async delete() {


    this.spinner.show();
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    setTimeout(()=>this.spinner.hide(),6000);
    this.httpClient.get<any>(ENV_PROD.urlApi + 'utilisateur/delete/' + this.utilisateurId,
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.loadListejoueurs();
            this.resetField();
            this.spinner.hide();
          }
        });


  }

  public resetField() {
    this.ngbModal.dismissAll();
     this.utilisateurId = '';
  }
  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public closeModal() {
    this.ngbModal.dismissAll()
  }

  /**
   * emitData
   */
  public emitnavigateToNavigation(userId) {
    localStorage.setItem('modificationId', userId);
    this.navigateToNavigation.emit({page:"modifierjoueur", titlePage:"Modification joueur"})
  }


  public emitNavigateToAfficheJoueur(userId) {
    localStorage.setItem('modificationId', userId);
    this.navigateToNavigation.emit({page:"afficherjoueur", titlePage:"Afficher joueur"})
  }


  public emitNavigateToShowArticles(user) {
  //  localStorage.setItem('modificationId', user.id);
    if(user.articles.length>0){
      localStorage.setItem('dataArticleparutilisateur', JSON.stringify(user));
      this.navigateToNavigation.emit({page:"articleparutilisateur", titlePage:"Article de " +user.prenom})
    }
  }


  public emitNavigateToShowReservation(item) {
    // modify to show articles, article de rakoto oh
    if(item.paiements.length>0){
      localStorage.setItem('dataPaiementreservation', JSON.stringify(item));
      this.navigateToNavigation.emit({page:"reservationpararticle", titlePage:"Réservation de " + item.prenom })
    }
  }

}
