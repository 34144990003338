import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from "../../../services/admin.service";
import { UserService } from "../../../services/user.service";
import { LocationStrategy } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('actionEmail') actionEmail: any;

  public messageemailinvalideOrDejaInBase: any = "";
  public emailpassword: any = "";
  public email: any;
  public password: any;
  public username: any;
  public help_block: any = "";

  constructor(
    private router: Router,
    private locationStrategy: LocationStrategy,
    private adminService: AdminService,
    private NgbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private userService:UserService
  ) {
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href)
    });
   }

  ngOnInit(): void {

  }

  public async emitDataEmail() {
    await <any>this.NgbModal.open(this.actionEmail);
  }

  public login() {
    let body = {
      nom: this.username,
      password: this.password
    };
    this.adminService.login(body)
      .subscribe((res: any) =>{
        if(res.status == 200) {
          localStorage.setItem('userToken', res.userToken);
          localStorage.setItem('adminId', res.data[0].id);
          localStorage.setItem('adminName', res.data[0].nom);
          localStorage.setItem('adminType', res.data[0].type);
          localStorage.setItem('adminEmail', res.data[0].email);
          localStorage.setItem('page', 'home');
          this.router.navigateByUrl('home')
        } else {
          this.help_block = "Le login ou le mot de passe est incorrect !"
        }
      })
  }


  public resetField() {
    this.NgbModal.dismissAll();
    this.emailpassword = '';
  }

  public envoieMailPassword(){
    this.spinner.show();
    this.userService.envoieMailPassword(this.emailpassword.toLowerCase())
      .subscribe((res: any) => {
        if(res.status === "emaillength"){
          this.spinner.hide();
          this.messageemailinvalideOrDejaInBase = "L'email que vous avez inséré n'existe pas dans notre répertoire.";
        }else{
          if(res.status = "OK"){
            this.spinner.hide();
            this.NgbModal.dismissAll();
            this.toastr.success(res.message, 'Little Spa',{timeOut: 6000,positionClass: 'toast-bottom-right',});
          }else{
            this.NgbModal.dismissAll();
            this.spinner.hide();
          }
        }
      });
  }
}
