import {Component, OnInit, ViewChild, EventEmitter, Output, Input} from '@angular/core';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {ENV_PROD} from "../../../environments/config-env";
import * as moment from "moment";

@Component({
  selector: 'app-reservationpararticle',
  templateUrl: './reservationpararticle.component.html',
  styleUrls: ['./reservationpararticle.component.css']
})
export class reservationpararticleComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @Output() navigateToModifyClubetmembre: EventEmitter<any> = new EventEmitter();
  @ViewChild('showImage') showImage: any;
  @ViewChild('confirmCheckDeletePls') confirmCheckDeletePls: any;

  public urlserver: string = ENV_PROD.urlApi;
  public dataPaiement: any = [];
  public dataArticle: any = null;
  public oneurlimage: string = "";
  public paiementId: number = null;
  public dtOptions: any = {};
  public emitData: EventEmitter<any> = new EventEmitter;
  public checkEnableDisablePlusieursArray: any = [];
  public dtTrigers = new Subject();

  constructor(
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit(): Promise<void> {

    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 1, 'desc' ]]
    };
    setTimeout(async ()=>{
      this.load()
      this.dtTrigers.next();
    },2500);

    
  }

  public async load(){

    const data = await localStorage.getItem('dataPaiementreservation');
    console.log('data',JSON.parse(data));
    this.dataPaiement = JSON.parse(data).paiements;
    this.dataArticle = JSON.parse(data);
    this.spinner.hide();
    return true;

  }

  ngOnChanges(): void {
    this.spinner.show();
    setTimeout(()=>{ this.spinner.hide() },10000);
    this.load();
    this.dtTrigers.next();

  }


  public formatPreuvedePaiement(stripecharges){
    // console.log("stripecharges_",stripecharges_.receipt_url)
    return  {url:JSON.parse(stripecharges).receipt_url, urlminimised:(JSON.parse(stripecharges).receipt_url).substr(0,28) + '...'};
  }

  public openModaPourSupprimerPlusieurs() {
    this.ngbModal.open(this.confirmCheckDeletePls)
  }


  public formatReservationDansDateLastMinute(reservation) {
    // this.dataArticle.startlastminute
    // this.dataArticle.endlastminute
    if(this.dataArticle.lastminute)
    return  moment(reservation).isBetween(this.dataArticle.startlastminute, this.dataArticle.endlastminute); // true
    else
      return false
  }
  public formatReservationDansDateLastMinuteFromdatalistUser(reservationdate, startlastminute, endlastminute, lastminute) {
    if(lastminute)
      return  moment(reservationdate).isBetween(startlastminute, endlastminute); // true
    else
      return false
  }


   public async supprimerPlusieurs() {
    let token =  await localStorage.getItem('userToken');
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    // this.joueurService.supprimerPlusieursmedias(this.checkEnableDisablePlusieursArray, token)
    //   .subscribe((res: any) => {
    //     this.load();
    //     this.resetField();
    //     this.spinner.hide()
    //   })
  }

  public checkEnableDisablePlusieurs(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursArray.length; i++){
        if(this.checkEnableDisablePlusieursArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursArray = this.checkEnableDisablePlusieursArray.filter(function (item) {
        return item !== id;
      });
    }
  }


    /**
   * filterByDate
   */
  public filterByDate(item) {
    let dateSplte = item.split(',');
    this.getBy2date(dateSplte[0], dateSplte[1])
  }

  public async getBy2date(start, end) {
    if(start !== undefined && end !== undefined){
    //   this.spinner.show();
    // setTimeout(()=>{  this.spinner.hide()},10000);
    // let token =  await localStorage.getItem('userToken');
    //   let adminId = localStorage.getItem('adminId');
    //   this.adminService.gettokenbyid(adminId)
    //     .subscribe(async (res: any) => {
    //       localStorage.setItem('userToken', res.data);
    //       token = res.data;
    //     });
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    //   let body = {
    //     startDate: start,
    //     endDate: end,
    //     id:this.userId
    //   };
    //     this.joueurService.getmediaBetweenTwoDates(token, body)
    //       .subscribe((res: any) => {
    //         this.spinner.hide();
    //         this.listPhotos = res.data;
    //         this.dtTigger.next()
    //       })
    // })
  }}


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.paiementId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  public  parsejson(jsonotparsed: any) {
    return  JSON.parse(jsonotparsed);
  }


  /**
   * delete
   */
  public async delete() {
    //
    // this.spinner.show();
    // setTimeout(()=>{
    //   this.spinner.hide()
    // },10000);
    // let token =  await localStorage.getItem('userToken');
    // let adminId = localStorage.getItem('adminId');
    // this.adminService.gettokenbyid(adminId)
    //   .subscribe(async (res: any) => {
    //     localStorage.setItem('userToken', res.data);
    //     token = res.data;
    //   });
    // this.joueurService.deleteonemedia(token, this.articleId)
    //   .subscribe((res: any) => {
    //     this.load();
    //     this.resetField();
    //     this.spinner.hide();
    //   })
  }

  public showsImage(urlimage) {
    if(urlimage != null && urlimage != ""){
      this.oneurlimage =  this.urlserver + 'article/' + urlimage;
      this.ngbModal.open(this.showImage, {windowClass: 'lg'})
    }
  }

  public resetField() {
    this.ngbModal.dismissAll();
     this.paiementId = null;
  }
  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public closeModal() {
    this.ngbModal.dismissAll()
  }



}
