<div class="card-body">

  <app-date-filter1 (itemConcat1)="filterByDate($event)" (itemListAll1)="load()"></app-date-filter1>

  <div class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigers">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th>Montant</th>
        <th>Nom de l'article</th>
        <th>Pptaire du bien</th>
        <th>Preuve de paiement</th>
        <th>Convive</th>
        <th>Reservé dans la date du promotion last minute</th>
        <th>Crée le</th>
        <th></th>
      </tr>
      </thead>
      <tbody *ngIf="dataArticle?.photoCoverture">
      <tr *ngFor="let item of dataPaiement" class="pointer-cl">
        <td >
          <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieurs(item?.id, $event)" >
        </td>
        <td (click)="showsImage(dataArticle?.photoCoverture)">
          <div *ngIf="urlserver != null && dataArticle != null && dataArticle?.photoCoverture != '' && dataArticle?.photoCoverture != null">
            <img alt="photos" src="{{dataArticle.photoCoverture? urlserver + 'article/' + dataArticle.photoCoverture : '../../../assets/images/placeholderImage.png' }}" style="width: 60px; height: 45px; padding:5px; borderRadius:3px; margin: 4px;"/>
          </div>
          <div *ngIf="dataArticle && (dataArticle?.photoCoverture == '' || dataArticle?.photoCoverture == null)">
            <img alt="photos" src="../../../assets/images/placeholderImage.png" style="width: 60px; height: 45px; padding:5px; borderRadius:3px; margin: 4px;"/>
          </div>
        </td>
        <td>{{item?.amount + '€' }}</td>
        <td>{{dataArticle?.nomArticle }}</td>
        <td>{{dataArticle?.user.prenom + ' ' + dataArticle?.user.nom }}</td>

        <td><a href="{{formatPreuvedePaiement(item.stripecharges).url}}"  target="_blank">{{formatPreuvedePaiement(item.stripecharges).urlminimised}}</a></td>
        <td>{{dataArticle.nbConvive}}</td>
        <td>{{formatReservationDansDateLastMinute(item.createdAt) == true ? 'Oui' : 'Non'}}</td>
        <td>{{item.createdAt | date:'yyyy-MM-dd'}}</td>
        <td style="display: flex; flex-direction: row">
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="!!dataArticle?.photoCoverture == false">
      <tr *ngFor="let item of dataPaiement" class="pointer-cl">
        <td >
          <input style="margin-left: 35%" type="checkbox" (change)="checkEnableDisablePlusieurs(item?.id, $event)" >
        </td>
        <td (click)="showsImage(item?.article?.photoCoverture)">
          <div *ngIf="urlserver != null && item?.article != null && item?.article?.photoCoverture != '' && item?.article?.photoCoverture != null">
            <img alt="photos" src="{{item?.article?.photoCoverture? urlserver + 'article/' + item?.article?.photoCoverture : '../../../assets/images/placeholderImage.png' }}" style="width: 60px; height: 45px; padding:5px; borderRadius:3px; margin: 4px;"/>
          </div>
          <div *ngIf="item?.article && (item?.article?.photoCoverture == '' || item?.article?.photoCoverture == null)">
            <img alt="photos" src="../../../assets/images/placeholderImage.png" style="width: 60px; height: 45px; padding:5px; borderRadius:3px; margin: 4px;"/>
          </div>
        </td>
        <td>{{item?.amount + '€' }}</td>
        <td>{{item?.article?.nomArticle }}</td>
        <td>{{item?.article?.user.prenom + ' ' + item?.article?.user.nom }}</td>

        <td><a href="{{formatPreuvedePaiement(item.stripecharges).url}}"  target="_blank">{{formatPreuvedePaiement(item.stripecharges).urlminimised}}</a></td>
        <td>{{item?.article?.nbConvive}}</td>
        <td>{{formatReservationDansDateLastMinuteFromdatalistUser(item.createdAt, item?.article?.startlastminute, item?.article?.endlastminute, item?.article?.lastminute) == true ? 'Oui' : 'Non'}}</td>
        <td>{{item.createdAt | date:'yyyy-MM-dd'}}</td>
        <td style="display: flex; flex-direction: row">

          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <button class="btn btn-sm btn-danger" (click)="openModaPourSupprimerPlusieurs()">
    Supprimer les items cochés
  </button>
</div>

<ng-template #confirmCheckDeletePls>
  <div class="card-body">
    Êtes-vous sûr de vouloir supprimer ces items?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="supprimerPlusieurs()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

<ng-template #showImage>
  <div style="width: 480px; height: 400px; display: flex; align-items: center; justify-content: center;">
    <img style="width: 400px; height: 400px;" src="{{oneurlimage}}"  />
  </div>
</ng-template>
