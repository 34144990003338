import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common'
import { NgxCollapseModule } from 'ngx-collapse';
import {MatFormFieldModule} from '@angular/material/form-field';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {ENV_PROD} from "../environments/config-env";
 // const config: SocketIoConfig = { url: ENV_PROD.urlApi1, options: {transports: ['websocket'],upgrade: false,secure: true,enabledTransports: ['ws', 'wss']}};
 // const config: SocketIoConfig = { url: ENV_PROD.urlApi1, options: {upgrade: false,secure: true,enabledTransports: ['ws', 'wss']}};
// ROUTE
import { RouteModule } from "./route/route.module";

// SERVICE
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { TokenInterceptorService } from './services/token-interceptor.service';

// Module installe
import { DataTablesModule } from 'angular-datatables';
import { NgbModule, NgbPaginationModule, NgbAlertModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { BackButtonDisableModule } from 'angular-disable-browser-back-button'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastrModule } from 'ngx-toastr';

// component
import {
  LoginComponent,
  HomeComponent,
  MenuComponent,
  listerutilisateurComponent,
  afficherjoueurComponent,
  ajouterentraineurComponent,
  modifierentraineurComponent,
  ajouterclubetmembreComponent,
  SchemajeuxComponent,
  PositionComponent,
  articleparutilisateurComponent,
  DiplomeComponent,
  ajouterclubComponent,
  ListerclubComponent,
  modifierclubComponent,
  ListerclubetmembreComponent,
  modifierclubetmembreComponent,
  CategorieComponent,
  NiveauComponent,
  reservationpararticleComponent,
   CguComponent, Validation_emailComponent, Cgu_outsessionComponent
} from "./application";

import { DateFilterComponent } from './shared/date-filter/date-filter.component';
import { DateFilter1Component } from './shared/date-filter1/date-filter1.component';
import { ListerentraineurComponent } from './application';
import {MatSelectModule} from "@angular/material/select";
import {ModificationadminComponent} from "./application";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MenuComponent,
    afficherjoueurComponent,
    listerutilisateurComponent,
    ListerentraineurComponent,
    ajouterentraineurComponent,
    modifierentraineurComponent,
    ajouterclubetmembreComponent,
    DateFilterComponent,
    DateFilter1Component,
    SchemajeuxComponent,
    PositionComponent,
    ModificationadminComponent,
    ajouterclubComponent,
    ListerclubComponent,
    modifierclubComponent,
    ListerclubetmembreComponent,
    modifierclubetmembreComponent,
    articleparutilisateurComponent,
    DiplomeComponent,
    CategorieComponent,
    NiveauComponent,
    reservationpararticleComponent,
    CguComponent,Validation_emailComponent, Cgu_outsessionComponent
  ],
  imports: [
    ToastrModule.forRoot(),
    BrowserModule,
    RouteModule,
    HttpClientModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxCollapseModule,
    MatCheckboxModule,
    BackButtonDisableModule.forRoot({preserveScrollPosition: true}),
    NgbPaginationModule,
    NgbAlertModule,
    NgbRatingModule,
    MatFormFieldModule,
    MatSelectModule,
    // SocketIoModule.forRoot(config)
  ],
  providers: [
    DatePipe,
    {
      provide: LocationStrategy,
      //useClass: PathLocationStrategy
      useClass: HashLocationStrategy
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

