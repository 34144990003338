<div class="hold-transition sidebar-mini layout-fixed">
  <div class="wrapper">

    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
        </li>
      </ul>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a (click)="toPage('admin')" class="nav-link">
            {{this.email}}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" data-target=".bd-example-modal-sm">
            <i class="fas fa-power-off"></i> Déconnexion
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-5">
<!--       Brand Logo-->
      <a (click)="toPage('home')" class="brand-link pointer-pt">
        <img src="../../../assets/images/logo-mymatch-transparent.png" alt="Logo" class="brand-image img-circle elevation-3"
             style="opacity: .8">
        <span class="brand-text font-weight-light" (click)="toPage('home')">{{nomapplication}}</span>
      </a>
<!--      <a (click)="toPage('home', 'Tableau de bord')" class="brand-link pointer-pt" style="text-align: center">-->
<!--        <img src="../../../assets/images/logo-mymatch-transparent1.png" alt="Logo" class="logo">-->
<!--      </a>-->
      <!-- Sidebar -->
      <div class="sidebar">

        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">

              <a _ngcontent-dpb-c106="" class="nav-link">
                <i class="fas fa-running nav-icon"></i>
                <p _ngcontent-dpb-c106="">
                  Utilisateurs
                </p>
              </a>
              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">

                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a _ngcontent-dpb-c106="" (click)="toPage('listerutilisateur')"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">Lister les utilisateurs</p>
                  </a>
                </li>
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('messagepouradmin', 'Message pour l\'admin')"-->
<!--                     class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Signalement joueur</p><span style=" margin-left:15px; border-radius: 50px;-->
<!--                     padding-top:5px;padding-bottom: 5px;padding-left: 8px; padding-right: 8px;-->
<!--                      background-color: #db0909; color:#fff">{{joueursignale}}</span>-->
<!--                  </a>-->
<!--                </li>-->

              </ul>
            </li>

            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">
              <a _ngcontent-dpb-c106="" class="nav-link">
                <i class="fas fa-running nav-icon"></i>
                <p _ngcontent-dpb-c106="">
                  Biens
                </p>
              </a>
              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">

                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a _ngcontent-dpb-c106="" (click)="toPage('articleparutilisateur')"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">Lister les biens</p>
                  </a>
                </li>
              </ul>
            </li>

            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">
              <a _ngcontent-dpb-c106="" class="nav-link">
                <i class="fas fa-running nav-icon"></i>
                <p _ngcontent-dpb-c106="">
                  Réservations
                </p>
              </a>
              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">

                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a _ngcontent-dpb-c106="" (click)="toPage('reservationpararticle')"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">Lister les reservations</p>
                  </a>
                </li>
              </ul>
            </li>

<!--            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">-->
<!--              <a _ngcontent-dpb-c106="" class="nav-link">-->
<!--                <i class="fas fa-male nav-icon"></i>-->
<!--                <p _ngcontent-dpb-c106="">-->
<!--                  Entraineurs-->
<!--                </p>-->
<!--              </a>-->
<!--              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('ajouterentraineur', 'Ajouter un entraineur')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Ajouter entraineur</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('listerentraineur', 'Entraineurs')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Lister entraineur</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </li>-->

<!--            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">-->
<!--              <a _ngcontent-dpb-c106="" class="nav-link">-->
<!--                <i class="fas fa-futbol nav-icon"></i>-->
<!--                <p _ngcontent-dpb-c106="">-->
<!--                  Clubs-->
<!--                </p>-->
<!--              </a>-->
<!--              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('ajouterclub', 'Ajouter un club')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Ajouter club</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('listerclub', 'Clubs')"-->
<!--                     class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">-->
<!--                      Lister club-->
<!--                    </p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('ajouterclubetmembre', 'Ajouter club et membre')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Ajouter club et membre</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('listerclubetmembre', 'Lister club et membre')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Lister club et membre</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </li>-->


<!--            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">-->
<!--              <a _ngcontent-dpb-c106="" class="nav-link">-->
<!--                <i class="fas fa-cogs nav-icon"></i>-->
<!--                <p _ngcontent-dpb-c106="">-->
<!--                  Configuration-->
<!--                </p>-->
<!--              </a>-->
<!--              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('position', 'Position')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Position sur le terrrain</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('schemajeux', 'Schéma de jeux')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Schéma de jeux</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('diplome', 'Diplômes')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Diplôme</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('categorie', 'Catégories')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Catégories</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('niveau', 'Carrière niveau')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Carrière niveau</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('cgu', 'CGU')" class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">CGU</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </li>-->
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">{{title}}</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content" [ngSwitch]="page">
        <div class="container-fluid" *ngSwitchCase="'home'">
          <div class="row">
            <div class="col-lg-3 col-6 pointer-pt">
              <div class="small-box bg-info">
                <div class="inner">
                  <h3>{{countUtilisateurs}}</h3>
                  <p>Utilisateurs</p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a (click)="toPage('listerutilisateur')" class="small-box-footer">Détail <i
                  class="fas fa-arrow-circle-right"></i></a>
              </div>
            </div>

            <div class="col-lg-3 col-6 pointer-pt">
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>{{countPaiements}}</h3>
                  <p>Biens</p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a (click)="toPage('articleparutilisateur')" class="small-box-footer">Détail <i
                  class="fas fa-arrow-circle-right"></i></a>
              </div>
            </div>

<!--            <div class="col-lg-3 col-6 pointer-pt">-->
<!--              <div class="small-box bg-danger">-->
<!--                <div class="inner">-->
<!--                  <h3>{{countclubs}}</h3>-->
<!--                  <p>Clubs</p>-->
<!--                </div>-->
<!--                <div class="icon">-->
<!--                  <i class="ion ion-bag"></i>-->
<!--                </div>-->
<!--                <a (click)="toPage('listerclub', 'Clubs')" class="small-box-footer">Détail <i-->
<!--                  class="fas fa-arrow-circle-right"></i></a>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="col-lg-3 col-6 pointer-pt">-->
<!--              <div class="small-box bg-gradient-warning">-->
<!--                <div class="inner">-->
<!--                  <h3 style="color: white">{{countclubetmembre}}</h3>-->
<!--                  <p style="color: white">Club et membres</p>-->
<!--                </div>-->
<!--                <div class="icon">-->
<!--                  <i class="ion ion-bag"></i>-->
<!--                </div>-->
<!--                <a (click)="toPage('listerclubetmembre', 'Lister club et membre')" class="small-box-footer">Détail <i-->
<!--                  class="fas fa-arrow-circle-right"></i></a>-->
<!--              </div>-->
<!--            </div>-->


          </div>
        </div>

        <div class="card" *ngSwitchCase="'admin'">
          <app-modificationadmin></app-modificationadmin>
        </div>
<!--        <div class="card" *ngSwitchCase="'ajouterjoueur'">-->
<!--          <app-ajouterjoueur   (navigateToPosition)="funcNavigateToPosition($event)"></app-ajouterjoueur>-->
<!--        </div>-->
<!--        <div class="card" *ngSwitchCase="'modifierjoueur'">-->
<!--          <app-modifierjoueur   (navigateToPosition)="funcNavigateToPosition($event)"></app-modifierjoueur>-->
<!--        </div>-->
        <div class="card" *ngSwitchCase="'listerutilisateur'">
          <app-listerutilisateur (navigateToNavigation)="funcnavigateToNavigation($event)"></app-listerutilisateur>
        </div>

        <div class="card" *ngSwitchCase="'afficherjoueur'">
          <app-afficherjoueur (navigateToNavigation)="funcnavigateToNavigation($event)"></app-afficherjoueur>
        </div>

        <div class="card" *ngSwitchCase="'ajouterclubetmembre'">
          <app-ajouterclubetmembre></app-ajouterclubetmembre>
        </div>
        <div class="card" *ngSwitchCase="'ajouterclub'">
          <app-ajouterclub></app-ajouterclub>
        </div>
<!--        <div class="card" *ngSwitchCase="'listerclub'">-->
<!--          <app-listerclub (navigateToModifyClub)="funcNavigateToModifyClub($event)"></app-listerclub>-->
<!--        </div>-->
        <div class="card" *ngSwitchCase="'articleparutilisateur'">
          <app-articleparutilisateur (navigateToNavigation)="funcnavigateToNavigation($event)"></app-articleparutilisateur>
        </div>
        <div class="card" *ngSwitchCase="'reservationpararticle'">
          <app-reservationpararticle (navigateToNavigation)="funcnavigateToNavigation($event)"></app-reservationpararticle>
        </div>
        <div class="card" *ngSwitchCase="'modifierclub'">
          <app-modifierclub ></app-modifierclub>
        </div>
<!--        <div class="card" *ngSwitchCase="'listerclubetmembre'">-->
<!--          <app-listerclubetmembre (navigateToModifyClubetmembre)="funcNavigateToModifyClubetmembre($event)"></app-listerclubetmembre>-->
<!--        </div>-->
        <div class="card" *ngSwitchCase="'modifierclubetmembre'">
          <app-modifierclubetmembre ></app-modifierclubetmembre>
        </div>
<!--        <div class="card" *ngSwitchCase="'ajouterentraineur'">-->
<!--          <app-ajouterentraineur   (navigateTo)="funcNavigateTo($event)"></app-ajouterentraineur>-->
<!--        </div>-->
<!--        <div class="card" *ngSwitchCase="'listerentraineur'">-->
<!--          <app-listerentraineurs (navigateToModifyEntraineur)="funcNavigateToModifyEntraineur($event)"></app-listerentraineurs>-->
<!--        </div>-->
<!--        <div class="card" *ngSwitchCase="'modifierentraineur'">-->
<!--          <app-modifierentraineur   (navigateToPosition)="funcNavigateToPosition($event)"></app-modifierentraineur>-->
<!--        </div>-->
        <div class="mt-2" *ngSwitchCase="'position'">
          <app-position></app-position>
        </div>
        <div class="mt-2" *ngSwitchCase="'niveau'">
          <app-niveau></app-niveau>
        </div>
        <div class="mt-2" *ngSwitchCase="'schemajeux'">
          <app-schemajeux></app-schemajeux>
        </div>
        <div class="mt-2" *ngSwitchCase="'diplome'">
          <app-diplome></app-diplome>
        </div>
        <div class="mt-2" *ngSwitchCase="'categorie'">
          <app-categorie></app-categorie>
        </div>
        <div class="mt-2" *ngSwitchCase="'cgu'">
          <app-cgu></app-cgu>
        </div>
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <footer class="main-footer">
    </footer>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>
</div>

<div class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
         <h4 class="text-center">Voulez-vous vraiment vous déconnecter?</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="sign_out()">OUI</button>
        <button type="button" class="btn btn-outline-dark" data-dismiss="modal">NON</button>
      </div>
    </div>
  </div>
</div>
