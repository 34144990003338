import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { AdminService } from "../../../services/admin.service";
import { DatatableLanguage } from "../../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as CryptoJS from 'crypto-js';
import {NgxSpinnerService} from "ngx-spinner";
import {ENV_PROD} from "../../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import {AlertService} from "../../../services/alert.service";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-modificationadmin',
  templateUrl: './modificationadmin.component.html',
  styleUrls: ['./modificationadmin.component.css']
})
export class ModificationadminComponent implements OnInit, OnChanges {
  @ViewChild('actionadmin') actionadmin: any;
  @ViewChild('actionadmin1') actionadmin1: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public listadmin: any = [];
  public tokenFromUI: string = "0123456789123456";
  public encrypted: string;
  public decrypted: string;
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public adminId: any;
  public adminName: any = "";
  public adminEmail: any = "";
  public adminAdress: any;
  public itemPassword: any = "";
  public nouveauPassword: any = "";
  public adminPassword: any = "";
  public adminconfirmPassword: any = "";
  public adminType: any;
  // check action
  public isUpdateWithPassword: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.spinner.show();
    this.getalladmin()
  }

  ngOnChanges(): void {
    this.loadadmin()
  }

  /**
   * loadadmin
   */
  public loadadmin(): void {
    this.spinner.show();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.decryptUsingAES256(this.adminPassword);
      this.getalladmin()
    })
  }

  private getalladmin(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      ////'Content-Type': 'application/json',
      Accept: "application/json",
       Authorization: "Bearer " + token
    });
    // this.httpClient.post<any>(ENV_PROD.urlApi + 'admins/getAll', {}).subscribe(
    this.httpClient.get<any>(ENV_PROD.urlApi + 'admins/getAll',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listadmin = res.data;
            this.dtTigger.next()
          }
        });
  }

  /**
   * emitDataadmin
   */
  public async emitDataadmin(item: any) {
    this.emitData.emit(item);
    this.adminId = item.id;
    this.adminName = item.nom;
    this.adminEmail = item.email;
    this.adminAdress = item.adresse;
    this.itemPassword = this.decryptUsingAES256(item.password);
    //this.adminPassword = this.decryptUsingAES256(item.password);
    // this.adminType = item.type;
    this.adminType = 1;
    await <any>this.ngbModal.open(this.actionadmin1)
  }

   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.adminId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

   /**
   * addadmin
   */
  public addadmin() {

     if(this.adminName === ""){
       alert("Le Nom ne pourrait-être pas vide.");
       return;
     }
     if(this.adminEmail === ""){
       alert("L'e-mail ne pourrait-être pas vide.");
       return;
     }
     if(this.adminPassword === ""){
       alert("Le mot de passe ne pourrait-être pas vide.");
       return;
     }
     if(this.adminPassword.trim().length <= 5){
       alert("Le mot de passe doit comporter au moins 6 caractères.");
       return;
     }
     if(this.adminPassword !== this.adminconfirmPassword){
       alert("Le mot de passe et la confirmation du mot de passe n'est pas identique.");
       return;
     }

    let body = {
      nom: this.adminName,
      email: this.adminEmail,
      adresse: this.adminAdress,
      password: this.adminPassword,
      // type: this.adminType
      type: 1
    };
     const token = localStorage.getItem('userToken');
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       Accept: "application/json",
       Authorization: "Bearer " + token
     });
     this.spinner.show();
     setTimeout(()=>this.spinner.hide(),10000);
     this.httpClient.post<any>(ENV_PROD.urlApi + 'admins/add',
       JSON.stringify(body),
       { headers: headers, }
   ).pipe(retry(1), catchError(this.alertService.handleError))
       .subscribe(
         (res) => {
           this.spinner.hide();
           if(res.status && res.status === 404 && res.message ){
             alert("Little spa \n " + res.message);
           }
           this.loadadmin();
           this.resetField();
         });
  }

   /**
   * updateadmin
   */
  public updateadmin(): void{
     this.adminName = this.adminName.trim();
     this.nouveauPassword = this.nouveauPassword.trim();
     this.adminconfirmPassword = this.adminconfirmPassword.trim();
     if(this.adminName === ""){
       alert("Le Nom ne pourrait-être pas vide.");
       return;
     }
     if(this.adminEmail === ""){
       alert("L'email ne pourrait-être pas vide.");
       return;
     }

    if(this.isUpdateWithPassword === false){
      if(this.adminPassword === ""){
        alert("Veuillez insérer votre mot de passe pour valider la modification.");
        return;
      }
      if(this.adminPassword !== "" && this.adminPassword !== this.itemPassword){
        alert("Le mot de passe pour valider la modification ne correspond pas.");
        return;
      }
      let body = {
        id: this.adminId,
        nom: this.adminName,
        email: this.adminEmail,
        adresse: this.adminAdress,
        password: this.encryptUsingAES256(this.itemPassword),
        // type: this.adminType
        type: 1
      };
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'admins/update',
        JSON.stringify(body),
      { headers: headers},
    ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
          this.loadadmin();
          this.resetField();
            if(res.status != 200)
              this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});

        })
    }else{
      if(this.adminPassword === ""){
        alert("Veuillez insérer votre ancien mot de passe.");
        return;
      }
      if(this.nouveauPassword === ""){
        alert("Veuillez insérer votre nouveau mot de passe.");
        return;
      }
      if(this.adminPassword.trim().length <= 5){
        alert("Le mot de passe doit comporter au moins 6 caractères.");
        return;
      }
      if(this.adminconfirmPassword === ""){
        alert("Veuillez confirmer votre nouveau mot de passe.");
        return;
      }
      if(this.adminPassword !== "" && this.adminPassword !== this.itemPassword){
        alert("L'ancien mot de passe que vous avez inséré ne correspond pas à l'ancien mot de passe.");
        return;
      }
      if(this.adminconfirmPassword !== "" && this.nouveauPassword !== "" && this.adminconfirmPassword !== this.nouveauPassword){
        alert("Le nouveau mot de passe et la confirmation du nouveau mot de passe ne correspond pas.");
        return;
      }

      let body = {
        id: this.adminId,
        nom: this.adminName,
        email: this.adminEmail,
        adresse: this.adminAdress,
        password: this.encryptUsingAES256(this.nouveauPassword),
        type: 1
      };
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'admins/update',
        JSON.stringify(body),
        { headers: headers },
      ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
          this.loadadmin();
          this.resetField();
          if(res.status != 200)
            this.toastr.success(res.message, 'Little Spa',{timeOut: 6000,positionClass: 'toast-bottom-right',});
        })
    }
  }

  /**
   * deleteadmin
   */
  public deleteadmin() {
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'admins/delete/'+this.adminId,
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.loadadmin();
          this.resetField();
          if(res.status != 200){
            this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
          }
        });

  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

   // GEstion field data
   public resetField() {
    this.ngbModal.dismissAll();
    this.adminName = '';
    this.adminEmail = '';
    this.adminAdress = '';
    this.itemPassword = '';
    this.adminPassword = '';
    this.nouveauPassword = '';
    this.adminconfirmPassword = '';
    this.isUpdateWithPassword = false;
    this.adminType = ''
  }

  public  encryptUsingAES256(code) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(code), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    return  this.encrypted
  }

  public decryptUsingAES256(code) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    this.decrypted = CryptoJS.AES.decrypt(
      code, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
      return this.decrypted.slice(1,this.decrypted.length - 1)
  }


  public ismodifierpassword(event) {
      this.isUpdateWithPassword = event.target.checked;
    }

}
