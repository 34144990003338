import {Component, OnInit, ViewChild, EventEmitter, Output, Input} from '@angular/core';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {ENV_PROD} from "../../../environments/config-env";
import * as moment from 'moment';
import {AlertService} from "../../services/alert.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, retry} from "rxjs/operators";

@Component({
  selector: 'app-articleparutilisateur',
  templateUrl: './articleparutilisateur.component.html',
  styleUrls: ['./articleparutilisateur.component.css']
})
export class articleparutilisateurComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @Output() navigateToNavigation: EventEmitter<any> = new EventEmitter();
  @ViewChild('showImage') showImage: any;
  @ViewChild('confirmCheckDeletePls') confirmCheckDeletePls: any;

  public urlserver: string = ENV_PROD.urlApi;
  public dataArticle: any = [];
  public oneurlimage: string = "";
  public articleId: number = null;
  public dtOptions: any = {};
  public emitData: EventEmitter<any> = new EventEmitter;
  public checkEnableDisablePlusieursArray: any = [];
  public dtTrigers = new Subject();

  constructor(
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private httpClient: HttpClient,
  ) { }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    setTimeout(()=>{ this.spinner.hide()},10000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 1, 'desc' ]]
    };
    setTimeout(async ()=>{
      this.spinner.hide();
      const data =await localStorage.getItem('dataArticleparutilisateur');
      this.dataArticle = JSON.parse(data).articles;
      this.dtTrigers.next();
    },2500);

  }
  
  public load(){

  }

  public emitNavigateToShowReservation(item) {
    if(item.paiements.length>0){
      localStorage.setItem('dataPaiementreservation', JSON.stringify(item));
      this.navigateToNavigation.emit({page:"reservationpararticle", titlePage:"Reservations de l'article " +item.nomArticle})
    }
  }

  public openModaPourSupprimerPlusieurs() {
    this.ngbModal.open(this.confirmCheckDeletePls)
  }


  private isoweek(date){
    const iso = moment(date).isoWeekday();
    switch (iso) {
      case 1:
        return  "Lundi";
      case 2:
        return "Mardi";
      case 3:
        return "Mercredi";
      case 4:
        return "Jeudi";
      case 5:
        return "Vendredi";
      case 6:
        return "Samedi";
      case 7:
        return "Dimanche";
    }
  }

  private isomonth(date){
    const iso = moment(date).format('MM');
    switch (iso) {
      case '01':
        return  "Janvier";
      case '02':
        return "Février";
      case '03':
        return "Mars";
      case '04':
        return "Avril";
      case '05':
        return "Mai";
      case '06':
        return "Juin";
      case '07':
        return "Juillet";
      case '08':
        return "Aöut";
      case '09':
        return "Septembre";
      case '10':
        return "Octobre";
      case '11':
        return "Novembre";
      case '12':
        return "Décembre";
    }
  }
  public formatLastMinute(startdate, enddate){
    const startdate_ = moment(startdate).format("yyyy-MM-DD");
    const startheure_ = moment(startdate).format("HH:mm");
    const enddate_ = moment(enddate).format("yyyy-MM-DD");
    const endheure_ = moment(enddate).format("HH:mm");
    let tex = "";

    const moisstart = this.isomonth(startdate);
    const anneestart = moment(startdate).format('yyyy');
    const lestart = moment(startdate).format('DD');
    const moisend = this.isomonth(startdate);
    const anneeend = moment(startdate).format('yyyy');
    const leend = moment(enddate).format('DD');

    if(startdate_ == enddate_){
      const lundidimanchestart = this.isoweek(startdate);
      tex = `${lundidimanchestart} le ${lestart} ${moisstart} ${anneestart} du ${startheure_} au ${endheure_}`;
    }else if(moment(startdate).format("yyyy") == moment(enddate).format("yyyy") &&
      moment(startdate).format("MM") == moment(enddate).format("MM")
    ){
      tex = `du ${lestart} ${moisstart} à ${startheure_} au ${leend} ${moisend} à ${endheure_} ${anneestart}`;
    }else{
      tex = `du ${lestart} ${moisstart} ${anneestart} à ${startheure_} au ${leend} ${moisend} ${anneeend} à ${endheure_}`;
    }
    return tex;
  }


   public async supprimerPlusieurs() {
    let token =  await localStorage.getItem('userToken');
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},10000);
    // this.joueurService.supprimerPlusieursmedias(this.checkEnableDisablePlusieursArray, token)
    //   .subscribe((res: any) => {
    //     this.load();
    //     this.resetField();
    //     this.spinner.hide()
    //   })
  }

  public checkEnableDisablePlusieurs(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursArray.length; i++){
        if(this.checkEnableDisablePlusieursArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursArray = this.checkEnableDisablePlusieursArray.filter(function (item) {
        return item !== id;
      });
    }
  }


    /**
   * filterByDate
   */
  public filterByDate(item) {
    let dateSplte = item.split(',');
    this.getBy2date(dateSplte[0], dateSplte[1])
  }

  public async getBy2date(start, end) {
    if(start !== undefined && end !== undefined){
    //   this.spinner.show();
    // setTimeout(()=>{  this.spinner.hide()},10000);
    // let token =  await localStorage.getItem('userToken');
    //   let adminId = localStorage.getItem('adminId');
    //   this.adminService.gettokenbyid(adminId)
    //     .subscribe(async (res: any) => {
    //       localStorage.setItem('userToken', res.data);
    //       token = res.data;
    //     });
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    //   let body = {
    //     startDate: start,
    //     endDate: end,
    //     id:this.userId
    //   };
    //     this.joueurService.getmediaBetweenTwoDates(token, body)
    //       .subscribe((res: any) => {
    //         this.spinner.hide();
    //         this.listPhotos = res.data;
    //         this.dtTigger.next()
    //       })
    // })
  }}


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.articleId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  public  parsejson(jsonotparsed: any) {
    return  JSON.parse(jsonotparsed);
  }


  /**
   * delete
   */
  public async delete() {

    this.spinner.show();
    setTimeout(()=>{ this.spinner.hide() },10000);
    const token =  await localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'article/deleteadmin/'+this.articleId,
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.load();
            this.resetField();
            this.spinner.hide();
          }
        });
  }

  public showsImage(image) {
    if(image != null && image != ""){
      this.oneurlimage =  this.urlserver + 'article/' + image;
      this.ngbModal.open(this.showImage, {windowClass: 'lg'})
    }
  }

  public resetField() {
    this.ngbModal.dismissAll();
     this.articleId = null;
  }
  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public closeModal() {
    this.ngbModal.dismissAll()
  }



}
